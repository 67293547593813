<template>
  <div>
    <!-- 短信发送 -->
    <el-form class="params-wrap" size="small"  :model="forms" :rule="formRules">
      <el-form-item>
        <el-input v-model="form.msg" clearable placeholder="请输入短信内容，必填"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.phone" clearable placeholder="请输入手机号码，为空时发送数据库"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" icon="el-icon-phone-outline"  @click="sms()">发送短信</el-button>
      </el-form-item>      
    </el-form>    
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { BaseUrl } from "@/common/config";
export default {
  name: "userSms",
  components: {
  },
  data() {
    return {
      BaseUrl,      
      form:{
        msg: "",
        phone: "15345604423"        
      },      
      formRules: {
        msg: [{ required: true, message: "请输入短信内容", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState(["OA"]),
  },
  methods: {
    ...mapActions("commonOptions", ["getSchoolCombo","dbMobiles"]),
    
    /** 发送短信 */
    sms() {
      let that = this
      if(that.form.msg == '' || that.form.msg == undefined || that.form.msg == null){
        this.$message({
          type: 'error',
          message: '短信内容不能为空'
        });
        return
      }
      this.$confirm('是否继续发送短信?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( async () => {
          try{            
            let res = await this.dbMobiles({...this.form})
            this.$message({
              type: 'success',
              message: res
            });
          }catch (error){
            this.$message.error("短信发送失败")
          }finally{

          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消短信发送'
          });         

        })
    },   
    
    
  },
  async mounted() {
    
  },
};
</script>
